<volvo-app-bar-top class="app-top-bar">
  <div class="brand-group">
    <volvo-app-bar-top-group>
      <img class="brand-logo" src="/assets/img/Volvo-Spread-Word-Mark-Black.svg" />
      <volvo-typography>LA TSA | {{ country | translate }}</volvo-typography>
    </volvo-app-bar-top-group>
  </div>
  <div class="brand-group">
    <volvo-app-bar-top-group>
      <volvo-dropdown
        appearance="fill"
        *ngIf="this.role === 'admin'"
        size="small"
        label="{{ 'Organização' | translate }}"
        floatingLabel
        [value]="getTranslationSvc.getTranslation(countryDropdown)"
        (change)="setCountryOrganization($event)"
      >
        <volvo-dropdown-option *ngFor="let country of countriesList" [value]="country?.code">
          {{ country?.name | translate }}</volvo-dropdown-option
        >
      </volvo-dropdown>
      <div id="parent">
        <!-- SVG Flags acquired from https://nucleoapp.com/svg-flag-icons -->
        <volvo-image
          matTooltip="{{ 'Português' | translate }}"
          matTooltipPosition="above"
          class="child"
          src="/assets/img/flags/br.svg"
          (click)="changeLanguage('br')"
          alt="Portuguese"
        ></volvo-image>
        <volvo-image
          matTooltip="{{ 'Inglês' | translate }}"
          matTooltipPosition="above"
          class="child"
          src="/assets/img/flags/us.svg"
          (click)="changeLanguage('us')"
          alt="English"
        ></volvo-image>
        <volvo-image
          matTooltip="{{ ('Espanhol' | translate) + ' Argentina' }}"
          matTooltipPosition="above"
          class="child"
          src="/assets/img/flags/ar.svg"
          (click)="changeLanguage('es-AR')"
          alt="Spanish"
        ></volvo-image>
        <volvo-image
          matTooltip="{{ ('Espanhol' | translate) + ' Peru' }}"
          matTooltipPosition="above"
          class="child"
          src="/assets/img/flags/pe.svg"
          (click)="changeLanguage('es-PE')"
          alt="Spanish"
        ></volvo-image>
      </div>
    </volvo-app-bar-top-group>
  </div>

  <volvo-app-bar-top-group>
    {{ profile?.givenName }} {{ profile?.surname }}
    <volvo-system-icon icon="user" size="24" style="margin-left: 0.5rem"></volvo-system-icon>
    <volvo-app-bar-top-item icon="menu-hamburger" (click)="_toggleSidebar()"></volvo-app-bar-top-item>
  </volvo-app-bar-top-group>
</volvo-app-bar-top>

<div>
  <div class="side-menu">
    <volvo-side-menu class="side-menu-volvo" mode="permanent" [open]="_opened">
      <volvo-side-menu-top-bar (toggle)="_toggleSidebar()">Menu</volvo-side-menu-top-bar>
      <volvo-side-menu-section-header></volvo-side-menu-section-header>
      <volvo-side-menu-item
        matTooltip="{{ 'Inconsistências' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="better-tooltip"
        [matTooltipDisabled]="_opened"
        icon="alert"
        [routerLink]="['/inconsistencies']"
      >
        <span
          class="stretch-badge"
          [matBadge]="inconsistencyCount ? inconsistencyCount : ''"
          matBadgePosition="after"
          matBadgeOverlap="false"
          matBadgeColor="warn"
          >{{ 'Inconsistências' | translate }}</span
        >
      </volvo-side-menu-item>
      <volvo-side-menu-item
        matTooltip="{{ 'Clientes' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="better-tooltip"
        [matTooltipDisabled]="_opened"
        icon="group-people"
        [routerLink]="['/customer']"
      >
        {{ 'Clientes' | translate }}
      </volvo-side-menu-item>
      <volvo-side-menu-item
        matTooltip="{{ 'Contratos' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="better-tooltip"
        [matTooltipDisabled]="_opened"
        icon="edit"
        [routerLink]="['/contract']"
      >
        {{ 'Contratos' | translate }}
      </volvo-side-menu-item>
      <volvo-side-menu-group [text]="'Faturas' | translate">
        <volvo-side-menu-item
          matTooltip="{{ 'Pré-Faturas' | translate }}"
          matTooltipPosition="right"
          matTooltipClass="better-tooltip"
          [matTooltipDisabled]="_opened"
          icon="checklist"
          [routerLink]="['/invoice/pre-invoice']"
        >
          {{ 'Pré-Faturas' | translate }}
        </volvo-side-menu-item>
        <volvo-side-menu-item
          matTooltip="{{ 'Faturas' | translate }}"
          matTooltipPosition="right"
          matTooltipClass="better-tooltip"
          [matTooltipDisabled]="_opened"
          icon="data-table"
          [routerLink]="['/invoice']"
        >
          {{ 'Faturas' | translate }}
        </volvo-side-menu-item>
      </volvo-side-menu-group>
      <volvo-side-menu-item
      *ngIf="countryParameters.hasPenalty"
        matTooltip="{{ 'Penalidades' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="better-tooltip"
        [matTooltipDisabled]="_opened"
        icon="receipt"
        [routerLink]="['/penalty']"
      >
        {{ 'Penalidades' | translate }}
      </volvo-side-menu-item>
      <!-- <ng-container *ngIf="countryParameters.inHouseService === true">
        <volvo-side-menu-item
          matTooltip="{{ 'Serviços In-House' | translate }}"
          matTooltipPosition="right"
          matTooltipClass="better-tooltip"
          [matTooltipDisabled]="_opened"
          icon="documents-stacked"
        >
          {{ 'Serviços In-House' | translate }}
        </volvo-side-menu-item>
      </ng-container> -->
      <volvo-side-menu-item
        matTooltip="{{ 'TSA One x LA TSA' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="better-tooltip"
        [matTooltipDisabled]="_opened"
        icon="board"
        [routerLink]="['/tsa']"
      >
        {{ 'TSA One x LA TSA' | translate }}
      </volvo-side-menu-item>
      <volvo-side-menu-item
        *ngIf="countryId === 'ARG'"
        matTooltip="{{ 'Relatórios' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="better-tooltip"
        [matTooltipDisabled]="_opened"
        icon="dashboard"
        [routerLink]="['/reports']"
      >
        {{ 'Relatórios' | translate }}
      </volvo-side-menu-item>
      <volvo-side-menu-group [text]="'Parametrização' | translate">
        <volvo-side-menu-item
          matTooltip="{{ 'Contábil' | translate }}"
          matTooltipPosition="right"
          matTooltipClass="better-tooltip"
          [matTooltipDisabled]="_opened"
          icon="data-table"
          [routerLink]="['/parameters-account-data']"
        >
          {{ 'Contábil' | translate }}
        </volvo-side-menu-item>

        <!-- <volvo-side-menu-item
          matTooltip="{{ 'Mercado' | translate }}"
          matTooltipPosition="right"
          matTooltipClass="better-tooltip"
          [matTooltipDisabled]="_opened"
          icon="documents-stacked"
        >
          {{ 'Mercado' | translate }}
        </volvo-side-menu-item> -->

        <volvo-side-menu-item
          matTooltip="{{ 'Bloqueio de Faturas' | translate }}"
          matTooltipPosition="right"
          matTooltipClass="better-tooltip"
          [matTooltipDisabled]="_opened"
          icon="ban"
          [routerLink]="['/invoice/block']"
        >
          {{ 'Bloqueio de Faturas' | translate }}
        </volvo-side-menu-item>
      </volvo-side-menu-group>

      <volvo-side-menu-item
        matTooltip="{{ 'Sair' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="better-tooltip"
        [matTooltipDisabled]="_opened"
        icon="logout"
        (click)="logout(); _toggleSidebar()"
      >
        {{ 'Sair' | translate }}
      </volvo-side-menu-item>
    </volvo-side-menu>

    <div class="add-background">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
