import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './Core/Navigation/Component/home/home.component';
import { NotFoundComponent } from './Core/Navigation/Component/not-found/not-found.component';
import { ProfileComponent } from './Core/Navigation/Component/profile/profile.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
    data: { title: 'Profile' },
  },
  {
    path: 'code',
    component: HomeComponent,
    canActivate: [MsalGuard],
    data: { title: 'Home' },
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
    data: { title: 'Home' },
  },
  {
    path: 'contract',
    loadChildren: () => import('./modules/contract/contract.module').then((m) => m.ContractModule),
    canActivate: [MsalGuard],
    data: { title: 'Contract' },
  },
  {
    path: 'invoice',
    loadChildren: () => import('./modules/invoice/invoice.module').then((m) => m.InvoiceModule),
    canActivate: [MsalGuard],
    data: { title: 'Invoice' },
  },
  {
    path: 'customer',
    loadChildren: () => import('./modules/customer/customer.module').then((m) => m.CustomerModule),
    canActivate: [MsalGuard],
    data: { title: 'Customer' },
  },
  {
    path: 'inconsistencies',
    loadChildren: () => import('./modules/inconsistencies/inconsistencies.module').then((m) => m.InconsistencyModule),
    canActivate: [MsalGuard],
    data: { title: 'Inconsistencies' },
  },
  {
    path: 'parameters-account-data',
    loadChildren: () =>
      import('./modules/parameters-account-data/parameters-account-data.module').then(
        (m) => m.ParametersAccountDataModule,
      ),
    canActivate: [MsalGuard],
    data: { title: 'Parameters-Account-Data' },
  },
  //{ path: 'reversion', loadChildren: () => import('./modules/reversion/reversion.module').then(m => m.ReversionModule), canActivate: [MsalGuard]},
  {
    path: 'tsa',
    loadChildren: () => import('./modules/cttsa/tsa.module').then((m) => m.TsaModule),
    canActivate: [MsalGuard],
    data: { title: 'TSA X CTTSA' },
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [MsalGuard],
    data: { title: 'Reports' },
  },
  {
    path: 'penalty',
    loadChildren: () => import('./modules/penalty/penalty.module').then((m) => m.PenaltyModule),
    canActivate: [MsalGuard],
    data: { title: 'Reports' },
  },

  //You need to keep this line in last
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'Page Not Found' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      //initialNavigation: !isIframe ? 'enabled' : 'disabled'
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule],
})
export class AppRoutingModule {}
