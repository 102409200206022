import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';
import { LoggingService } from './services/logging.service';
import { LocalStorageUtils } from './shared/utils/localstorage';
import { SetDefaultLanguageService } from './shared/utils/setDefaultLanguage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'La TSA';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public LocalStorage = new LocalStorageUtils();
  static globalRole: any;
  groupIds: any[] = [];
  groups: any;

  private refreshSubscription!: Subscription;
  private intervalId: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    private loggingService: LoggingService,
  ) {
    AppComponent.globalRole = '';
  }

  get staticGlobalRole() {
    return AppComponent.globalRole;
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$.subscribe((msg: EventMessage) => {
      if (msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        this.setupTokenRefresh();
      }
    });

    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());

    this.spinner.show();
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.getAccessTokenAndCallGraphAPI();
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.router.routerState.root;
          const segments: string[] = [];
          while (route.firstChild) {
            route = route.firstChild;
            if (route.snapshot.url.length) {
              segments.push(...route.snapshot.url.map((segment) => segment.path));
            }
          }
          return { route, segments };
        }),
        map(({ route, segments }) => {
          let title = route.snapshot.data['title'] || 'No Title'; // Default title if not set
          let url = '/' + segments.join('/'); // Construct full URL
          return { title, url };
        }),
      )
      .subscribe(({ title, url }) => {
        this.loggingService.logPageView(title, url); // Log the page view with title and URL
      });
  }

  private setupTokenRefresh() {
    // Clear existing interval if any
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Setup token refresh every 50 minutes
    this.intervalId = setInterval(() => {
      this.refreshToken();
    }, 3000000);
  }

  private refreshToken() {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const request = {
        account: accounts[0],
        scopes: ['user.read'], // Specify the necessary scopes
        forceRefresh: true, // Force token refresh
      };
      this.authService
        .acquireTokenSilent(request)
        .pipe(
          tap((response) => console.log('Token refreshed:', Date.now() / 1000)),
          catchError((error) => {
            console.error('Error during token refresh:', error);
            return of(null); // Handle the error and return an Observable to keep the stream alive
          }),
        )
        .subscribe();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.setLocaStorageToken();
    this.setCountryByUserToken();
    this.setLanguageByUserToken();
  }

  setLocaStorageToken() {
    if (this.authService.instance.getAllAccounts()[0] == undefined) {
      this.loggingService.logException(new Error('Failure on acquiring Token'));
      this.router.navigate(['/']);
    }

    this.loggingService.logTrace('Token acquired by user');

    let environment = this.authService.instance.getAllAccounts()[0].environment;
    let homeAccountId = this.authService.instance.getAllAccounts()[0].homeAccountId;
    let idTokenClaims = <any>this.authService.instance.getAllAccounts()[0].idTokenClaims;
    localStorage.setItem(
      'TokenKey',
      homeAccountId + '-' + environment + '-idtoken-' + idTokenClaims.aud + '-' + idTokenClaims.tid + '---',
    );
    localStorage.setItem('TokenClaims', homeAccountId + '-' + environment + '-' + idTokenClaims.tid);
    localStorage.setItem(
      'TokenAccess',
      homeAccountId +
        '-' +
        environment +
        '-accesstoken-' +
        idTokenClaims.aud +
        '-' +
        idTokenClaims.tid +
        '-groupmember.read.all user.read user.read.all profile openid email' +
        '--',
    );
    localStorage.setItem('TokenClaims2', JSON.stringify(idTokenClaims));
  }

  public setCountryByUserToken(): void {
    let key = <any>localStorage.getItem('TokenClaims2');
    key = JSON.parse(key);
    let isSet = <any>localStorage.getItem('countryCode');

    if (isSet === null) {
      //let token = <any>localStorage.getItem(key.toString());

      <any>localStorage.setItem('countryCode', key.ctry);
    }
  }

  setLanguageByUserToken() {
    let isSet = <any>localStorage.getItem('language');

    if (isSet === null) {
      let countryKey = <any>localStorage.getItem('countryCode');
      <any>localStorage.setItem('language', this.ReturnLanguageByUserCountry(countryKey));
    }
  }

  private ReturnLanguageByUserCountry(countryKey: any): string {
    countryKey = countryKey.toLowerCase();
    if (countryKey == 'br') {
      return countryKey;
    } else if (countryKey == 'ar') {
      return 'es-AR';
    } else if (countryKey == 'pe') {
      return 'es-PE';
    }
    return countryKey;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    // The line below was not working properly, we need to check it, to understand the reason why.

    // this.authService.logout(); //

    //Because of it, we are using a simple redirect to the Volvo SharePoint instead.

    window.location.replace('https://volvogroup.sharepoint.com/Pages/start.aspx');
  }

  protected serviceError(response: Response | any) {
    let customError: string[] = [];

    this.loggingService.logException(response.error);
    if (response.statusText === 'Unknown Error') {
      customError.push('Ocorreu um erro desconhecido');
      response.error.errors = customError;
    }

    if (response.status === 401) {
      let countryCode = localStorage.getItem('countryCode');
      let language = localStorage.getItem('language');
      localStorage.clear();
      localStorage.setItem('countryCode', countryCode!);
      localStorage.setItem('language', language!);
      window.location.reload();
    }
    return throwError(response);
  }

  getUserProfileRole() {
    let key = <any>localStorage.getItem('TokenClaims2');
    key = JSON.parse(key);
    //let token = <any>localStorage.getItem(key.toString());
    AppComponent.globalRole = key.roles;
  }

  public getAccessTokenAndCallGraphAPI() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.LocalStorage.getAccessToken()}`,
      }),
    };

    this.http
      .get(
        'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true&$select=id&$top=999',
        httpOptions,
      )
      .subscribe((result) => {
        this.groups = result;
        this.groups.value.forEach((group: { id: any }) => {
          this.groupIds.push(group.id);
        });
      });
    this.getUserProfileRole();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
