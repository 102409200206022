<div class="container mt-n10" style="margin-top: 10%" *ngIf="false">
  <div class="row">
    <div class="col-xxl-4 col-xl-12 mb-4">
      <div class="card h-100">
        <div class="card-body h-100 d-flex flex-column justify-content-center py-5 py-xl-4">
          <div class="row align-items-center">
            <div class="col-xl-8 col-xxl-12">
              <div class="text-center text-xl-left text-xxl-center px-4 mb-4 mb-xl-0 mb-xxl-4">
                <h1
                  class="text-primary"
                  style="font-family: 'Volvo Novum', sans-serif !important; color: #0d3896 !important"
                >
                  {{ 'Bem vindo ao LA TSA!' }}
                </h1>
                <p class="text-gray-700 mb-0" style="color: #464855 !important">
                  {{ 'Infelizmente, parece que você não tem acesso à aplicação!' }}
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-xxl-12 text-center">
              <img class="img-fluid" src="/assets/at-work.JPG" style="max-width: 19rem" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
