import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BaseService } from 'src/app/services/base.service';
import { LoggingService } from 'src/app/services/logging.service';
import { Operation } from 'src/app/shared/models/Operations';
import { ResponseResultModel } from 'src/app/shared/models/ResponseResultModel';
import { PagedResponse } from '../../../services/grid-data-source.service';
import { Customer, EditCustomer } from '../models/customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseService {
  constructor(
    private http: HttpClient,
    protected override loggingService: LoggingService,
  ) {
    super(loggingService);
  }

  getAllCustomers(
    page: number,
    pageSize: number,
    filterObject: any,
    countryCode: string,
  ): Observable<PagedResponse<Customer>> {
    return this.http
      .get<
        PagedResponse<Customer>
      >(`${environment.UrlApiContract}Customer/${countryCode}/${page}/${pageSize}`, { params: filterObject, headers: super.GetAuthTokenHeader().headers })
      .pipe(
        map(super.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  // Use sparingly! Returns a ton of data
  getCustomerSummary(countryCode: string): Observable<ResponseResultModel> {
    return this.http
      .get(`${environment.UrlApiContract}Customer/Summary/${countryCode}`, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  updateCustomer(editCustomer: EditCustomer): Observable<Customer> {
    return this.http.patch(`${environment.UrlApiContract}Customer`, editCustomer, super.GetAuthTokenHeader()).pipe(
      map(this.extract),
      catchError((err) => this.serviceError(err)),
    );
  }

  getCustomerParameters(countryCode: string, customerId: string): Observable<ResponseResultModel> {
    return this.http
      .get(
        `${environment.UrlApiContract}CustomerParameter/GetByCustomerId/${countryCode}/${customerId}`,
        super.GetAuthTokenHeader(),
      )
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  getCustomerOperationParameters(countryCode: string, customerOperationId: string): Observable<ResponseResultModel> {
    return this.http
      .get(
        `${environment.UrlApiContract}CustomerParameter/GetByCustomerOperationId/${countryCode}/${customerOperationId}`,
        super.GetAuthTokenHeader(),
      )
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  getCustomerOperationByCustomerId(countryCode: string, customerId: string): Observable<ResponseResultModel> {
    return this.http
      .get(
        `${environment.UrlApiContract}CustomerOperation/GetCustomerOperationByCustomerId/${countryCode}/${customerId}`,
        super.GetAuthTokenHeader(),
      )
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  createNewCustomerOperation(countryCode: string, operation: Operation): Observable<ResponseResultModel> {
    return this.http
      .post(`${environment.UrlApiContract}CustomerOperation/${countryCode}`, operation, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  editCustomerOperation(countryCode: string, operation: Operation): Observable<ResponseResultModel> {
    return this.http
      .patch(`${environment.UrlApiContract}CustomerOperation/${countryCode}`, operation, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  deleteCustomerOperation(countryCode: string, operationId: string): Observable<ResponseResultModel> {
    return this.http
      .delete(
        `${environment.UrlApiContract}CustomerOperation/${countryCode}/${operationId}`,
        super.GetAuthTokenHeader(),
      )
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  getDealers(countryCode: string): Observable<ResponseResultModel> {
    return this.http
      .get(`${environment.UrlApiContract}Dealer/GetAllDealersByCountry/${countryCode}`, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  getPortfolioOwners(): Observable<ResponseResultModel> {
    return this.http.get(`${environment.UrlApiContract}PortfolioOwner`, super.GetAuthTokenHeader()).pipe(
      map(this.extract),
      catchError((err) => this.serviceError(err)),
    );
  }

  getCustomerEmailResponsible(countryCode: string) {
    return this.http
      .get(`${environment.UrlApiContract}CustomerEmailResponsible/${countryCode}`, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  getCustomerEmails(countryCode: string, getObject: any) {
    return this.http
      .get(`${environment.UrlApiContract}CustomerEmail/${countryCode}`, {
        params: getObject,
        headers: super.GetAuthTokenHeader().headers,
      })
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  createCustomerEmail(createObject: any): Observable<ResponseResultModel> {
    return this.http.post(`${environment.UrlApiContract}CustomerEmail`, createObject, super.GetAuthTokenHeader()).pipe(
      map(this.extract),
      catchError((err) => this.serviceError(err)),
    );
  }

  updateCustomerEmail(updateObject: any): Observable<ResponseResultModel> {
    return this.http.put(`${environment.UrlApiContract}CustomerEmail`, updateObject, super.GetAuthTokenHeader()).pipe(
      map(this.extract),
      catchError((err) => this.serviceError(err)),
    );
  }

  deleteCustomerEmail(countryCode: string, emailId: string): Observable<ResponseResultModel> {
    return this.http
      .delete(`${environment.UrlApiContract}CustomerEmail/${countryCode}/${emailId}`, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  updateCustomerParameters(countryCode: string, customerParameter: any): Observable<ResponseResultModel> {
    return this.http
      .put(
        `${environment.UrlApiContract}CustomerParameter/${countryCode}`,
        customerParameter,
        super.GetAuthTokenHeader(),
      )
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  getCountryParameters(countryCode: string): Observable<ResponseResultModel> {
    return this.http
      .get(`${environment.UrlApiContract}CountryParameter/${countryCode}`, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  updateCustomerHasOperation(countryCode: string, updateCustomerObject: any) {
    return this.http
      .patch(
        `${environment.UrlApiContract}CustomerParameter/${countryCode}`,
        updateCustomerObject,
        super.GetAuthTokenHeader(),
      )
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  updateCustomerCustomerParameters(countryCode: string, updateCustomerObject: any) {
    return this.http
      .patch(`${environment.UrlApiContract}Customer/${countryCode}`, updateCustomerObject, super.GetAuthTokenHeader())
      .pipe(
        map(this.extract),
        catchError((err) => this.serviceError(err)),
      );
  }
}
